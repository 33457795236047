import React, { FC, useEffect, useState } from "react";
import { useLinkParams } from "../../../hooks/useLinkParams";
import { useAppSelector } from "../../../store";
import { useLoggedUser } from "../../../store/user/LoggedUserHooks";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { getReport } from "../../../utilities/userUtils";
import { PreviewModalForReportList } from "./PreviewModalForReportList";

interface ReportPreviewFromEmailProps {
  setSelectedReportId: (reportId: string) => void;
}

export const ReportPreviewFromEmail: FC<ReportPreviewFromEmailProps> = ({ setSelectedReportId }) => {
  const { linkParams } = useLinkParams();
  const { userToken } = useLoggedUser();
  const lang = useAppSelector(selectLanguage);
  const [showPreview, setShowPreview] = useState(false);
  const [report, setReport] = useState(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const report = await getReport(userToken, linkParams.id, lang);
        setReport(report);
        setShowPreview(true);
        setSelectedReportId(report.report_id);
      } catch (e) {
        console.log(e); // TODO: either error toast or error middleware
      }
    };

    if (linkParams?.type === "report" && linkParams?.id && userToken && lang) {
      fetchReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, lang, linkParams]);

  return <PreviewModalForReportList showPreview={showPreview} setShowPreview={setShowPreview} report={report} />;
};
