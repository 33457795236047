import { useAuth0 } from "@auth0/auth0-react";
import { LoadingIndicator } from "@secuis/ccp-react-components";
import i18next from "i18next";
import React, { Ref, useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TrackingProvider } from "../context/TrackingContext";
import { useAppErrorNotification } from "../hooks/useAppErrorNotification";
import { useLinkParams } from "../hooks/useLinkParams";
import { useReportTypes } from "../hooks/useReportTypes";
import { useRoles } from "../hooks/useRoles";
import { useSchedule } from "../hooks/useSchedule";
import { useSearchParam } from "../hooks/useSearchParam";
import { useTagsAndRegions } from "../hooks/useTagsAndRegions";
import { useUserData } from "../hooks/useUserData";
import { useAppDispatch } from "../store";
import { usePreloadMetadata } from "../store/items/ItemsHooks";
import { toolbarActions } from "../store/toolbar/ToolbarSlice";
import { useLoggedUser } from "../store/user/LoggedUserHooks";
import { useLazyGetPreferencesQuery, useLazyGetRolesQuery, useLazyGetUserByEmailQuery, useLazyGetUserByIdQuery } from "../store/user/UserApi";
import { userActions } from "../store/user/UserSlice";
import { getPreferredLanguage } from "../utilities/preferencesUtilities";
import { getIsEmbedded, getIsEmbeddedInMobile } from "../utilities/queryUtils";
import { getDataFromBE } from "../utilities/userUtils";
import { getEmail, getUserName, isUserNameFilled } from "../utilities/utils";
import { Navigation } from "./Navigation/Navigation";
import { Toolbar } from "./Navigation/Toolbar/Toolbar";
import { ApplicationContainer, HomePageContainer, HomePageContent, LoadingWrapper } from "./styled";

const AuthenticatedApp: React.FC<{ scrollRef?: Ref<HTMLDivElement> }> = ({ scrollRef }) => {
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { canSeeBriefs, cantSeeItems } = useRoles();
  const { setUserIdDeprecated, userId } = useUserData();
  const { userToken } = useLoggedUser();
  const { uploadReportsTypes } = useReportTypes();
  const { uploadSchedules } = useSchedule();
  const { uploadTags } = useTagsAndRegions();
  const a = useSearchParam("a");
  const c = useSearchParam("c");

  useAppErrorNotification();
  usePreloadMetadata();
  const { navigateUsingLinkParams, linkParams } = useLinkParams();

  const [getPreferences] = useLazyGetPreferencesQuery();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [getUserByEmail] = useLazyGetUserByEmailQuery();
  const [getRoles] = useLazyGetRolesQuery();

  const {
    userData: { isLoaded },
  } = useLoggedUser();

  const fetchData = useCallback(async () => {
    try {
      const tags = await getDataFromBE(userToken, "/tags");
      const schedules = await getDataFromBE(userToken, "/report-types?product_id=1");
      uploadSchedules(schedules.data);
      uploadReportsTypes(schedules.data);
      uploadTags(tags.data);

      const userDataFunction = user?.email ? () => getUserByEmail(user.email) : () => getUserById(userId);

      userDataFunction()
        .unwrap()
        .then((userData) => {
          setUserIdDeprecated(userData.id);
          dispatch(userActions.setUserId(userData.id));
          getPreferences(userData.id)
            .unwrap()
            .then((preferences) => {
              dispatch(userActions.setPreferences(preferences));
            });

          const language = getPreferredLanguage(userData.preferences);
          if (language && language !== i18next.language) {
            i18next.changeLanguage(language);
          }

          getRoles(userData.id)
            .unwrap()
            .then((roles) => {
              const userProperties = {
                name: getUserName(userData),
                isEmbedded: getIsEmbedded(a),
                isEmbeddedInMobile: getIsEmbeddedInMobile(a),
                isUserNameFilled: isUserNameFilled(userData),
                roles: roles,
                email: getEmail(userData),
                isLoaded: true,
              };
              dispatch(userActions.setUserData(userProperties));
            })
            .catch(() => true);
        })
        .catch(() => true);

      if (c === "settings") {
        navigate("/settings", { replace: true });
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a, user?.email, userId, userToken]);

  useEffect(() => {
    return () => {
      dispatch(
        toolbarActions.setToolbar({
          visible: false,
          isOpen: false,
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (userToken) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const pathsWithScroll = ["/", "//", "/reports"];

  useEffect(() => {
    linkParams && canSeeBriefs !== null && cantSeeItems !== null && navigateUsingLinkParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkParams, canSeeBriefs, cantSeeItems]);

  return (
    <TrackingProvider>
      {isLoaded ? (
        <ApplicationContainer data-testid="auth-app">
          <Navigation />
          <HomePageContainer shouldShowScroll={!pathsWithScroll.includes(pathname)} ref={scrollRef}>
            <HomePageContent>
              <Outlet />
            </HomePageContent>
          </HomePageContainer>
          <Toolbar />
        </ApplicationContainer>
      ) : (
        <LoadingWrapper>
          <LoadingIndicator size="L" />
        </LoadingWrapper>
      )}
    </TrackingProvider>
  );
};

export default AuthenticatedApp;
