import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import { itemsActions } from "../store/items/ItemsSlice";
import { useRoles } from "./useRoles";
import { useSearchParam } from "./useSearchParam";

interface LinkParams {
  id: string;
  type: "brief" | "report" | "sendout";
}

export const useLinkParams = () => {
  const c = useSearchParam("c");
  const [linkParams, setLinkParams] = useState<LinkParams | null>(null);
  const { canSeeBriefs, cantSeeItems } = useRoles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!c) return;

    let linkParams;
    try {
      linkParams = JSON.parse(c.toString());
    } catch (e) {
      linkParams = {
        id: c,
        type: "report",
      };
    }

    setLinkParams(linkParams);
  }, [c, canSeeBriefs, cantSeeItems]);

  const navigateUsingLinkParams = useCallback(() => {
    if (!c) return;

    if (c === "settings") {
      navigate("/settings");
    }

    if (!linkParams) return;

    switch (linkParams.type) {
      case "sendout":
        navigate(canSeeBriefs ? "/briefs" : cantSeeItems ? "/items" : "/");
        break;
      case "brief":
        (canSeeBriefs || cantSeeItems) && dispatch(itemsActions.setPreview({ itemId: linkParams.id }));
        navigate(canSeeBriefs ? "/briefs" : cantSeeItems ? "/items" : "/");
        break;
      case "report":
        navigate("/reports?c=" + linkParams.id);
    }
  }, [c, canSeeBriefs, cantSeeItems, linkParams]);

  return { linkParams, navigateUsingLinkParams };
};
